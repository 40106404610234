import React from 'react'
import BaseComponent, { errorMap } from './BaseComponent'
import Modal from './Modal'
import _ from 'lodash'
import ReactPaginate from 'react-paginate';
import JSONPretty from 'react-json-pretty';
import 'react-json-pretty/themes/monikai.css';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import { registerLocale } from "react-datepicker";
import pl from 'date-fns/locale/pl';

registerLocale('pl', pl)

export default class ListPage extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { list: [], itemId: null, preloader: true, consumer: "", page: 0, pages: 0, category: "test", sortBy: "publicationDate", filters: {}, mapUUID: "", dateBefore: new Date(), dateAfter: new Date(), email: "", phone: "", type: "chic", transactionId:"", value:"", receiptValue:"",timestamp:"", limit: 20, offset: 0 }
    this._bind('delete', 'getData', 'searchUpdated', 'closeConfirm', 'handlePageClick')

    this.handleSearch = _.debounce( () => {
      this.setState({ list: [], preloader: true })
      this.getData(this.state.mapUUID)
    }, 600);
  }

  getData(mapUUID) {
    let url = this.props.API+this.props.data.endpointList
    let params = {
      touchpoint: this.state.type,
      consumer: {
        id: this.state.consumer,
      },
      order: {
        transactionId: this.state.transactionId,
        value: this.state.value ,
        receiptValue: this.state.receiptValue,
        timestamp: this.state.timestamp
    }
    
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
      } else {
        //console.log(result)
        let res = result.data || []
        if (res === null) {
          res = []
        }
        const ordered = res
        this.setState({ pages: Math.ceil(result.data?.total?.length/20) })
        this.setState({ list: ordered, preloader: false, pages: result.data ? result.data.pages : [] })
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Punkty za zakup dodane."}}), true);
      }
    }, (error) => {
      console.log(error)
    })
  }

  getLatestMap() {
    let url = this.props.API+"/map/list"
    let params = {
      offset: 0,
      limit: 20
    }

    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
        },
        body: JSON.stringify(params)
      })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident)}}), true);
      } else {
        const geocoded = _.filter(result.data, {geocoded: true})
        const lastMap = geocoded[0]
        console.log(lastMap)
        this.setState({ mapUUID: lastMap?.uuid })
        this.getData(lastMap?.uuid)
      }
    }, (error) => {
      console.log(error)
    })
  }

//   getList() {
//     let url = this.props.API+"/order/touchpoints"


//     fetch(url, {
//       method: "POST",
//       headers: {
//           "Content-Type": "application/json; charset=utf-8",
//           "Authorization": "Bearer " + sessionStorage.getItem('token')
//         },
//         body: JSON.stringify()
//       })
//     .then((response) => {
//         return response.json()
//     })
//     .then((result) => {
//       if (!result.status.success) {
//         window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident)}}), true);
//       } else {
//         const geocoded = result.data
//         console.log(geocoded)
//         this.setState({ mapUUID: lastMap?.uuid })
//         this.getData(lastMap?.uuid)
//       }
//     }, (error) => {
//       console.log(error)
//     })
//   }

  componentDidMount() {
    if(this.props.data.table === "address") {
      this.getList()
      this.getLatestMap()
    } else {
      // this.getData()
    }
  }

  delete(id) {
    this.setState({ preloader: true })
    let url = this.props.API+this.props.data.endpointDelete
    const params = {
      [this.props.data.table]: {
        uuid: id
      }
    }
    fetch(url, {
      method: "POST",
      headers: {
          "Content-Type": "application/json; charset=utf-8",
          "Authorization": "Bearer " + sessionStorage.getItem('token')
      },
      body: JSON.stringify(params)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
      //console.log(result)
      this.setState({ confirm: false, itemId: null })
      this.getData()
      if (!result.status.success) {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : errorMap(result.data?.error?.ident || result.data?.error)}}), true);
      } else {
        window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "green", 'content' : "Pomyślnie usunięto."}}), true);
      }
    }, (error) => {
      console.log(error)
      window.dispatchEvent(new CustomEvent("NOTIFY", { 'detail': {'type': "red", 'content' : "Something went wrong."}}), true);
      this.setState({ preloader: false })
    })
  }

  searchUpdated(term) {
    this.setState({consumer: term})
  }

  closeConfirm() {
    this.setState({ confirm: false, itemId: null })
  }

  handlePageClick(data) {
    this.setState({ page: data.selected, preloader: true }, () => {
      this.getData(this.state.mapUUID)
    })
  }

  render () {
    let list = null

    let filteredList = this.state.list

    const Confirm = (
      <div className="confirm">
        <h4>Are you sure?</h4>
        <div className="btn-holder">
          <button className="btn" onClick={ this.closeConfirm }><span>No</span></button>
          <button className="btn" onClick={ this.delete.bind(null, this.state.itemId) }><span>Yes</span></button>
        </div>
      </div>
    )

    if (filteredList?.length > 0) {
      const sortedList = this.props.data.table === "articles" || this.props.data.table === "offers" || this.props.data.table === "events" || this.props.data.table === "faq" ? _.orderBy(filteredList, [this.state.sortBy], ['desc']) : filteredList
      list = sortedList.map((item, i) => {
        return (
          <li key={ i }>
            <JSONPretty id="json-pretty" data={item}></JSONPretty>
          </li>
        )
      })
    }

    return (
      <section className="list">
        <div className="container">
    			<h1>{ this.props.data.name }</h1>
          <div className='s-box'>
            <label>Create Purchase From:</label>
            <div className="">
              <select name="type" value={this.state.type} onChange={(e) => { this.setState({type: e.target.value}) }}>
                <option value="chic">chic</option>
                <option value="discoverglopl">discoverglopl</option>
                <option value="velopl">velopl</option>
                <option value="esc">esc</option>
                <option value="alterzone">alterzone</option>
                <option value="altercloud">altercloud</option>
              </select>
            </div>
            <label>Consumer:</label>
            <div className="">
              <input type="text" placeholder="consumer" value={ this.state.consumer } onChange={ (e) => { this.setState({ consumer: e.target.value })} }/>
            </div>
            <label>transactionId:</label>
            <div className="">
              <input type="text" placeholder="transactionId" value={ this.state.transactionId } onChange={ (e) => { this.setState({ transactionId: e.target.value })} }/>
            </div>
            <label>Order Value:</label>
            <div className="">
              <input type="number" min="1" step="1" pattern="\d+" placeholder="value" title="tylko liczby" value={ this.state.value } onChange={ (e) => { this.setState({ value: parseInt(e.target.value) })} }/>
            </div>
            <label>Receipt Value:</label>
            <div className="">
              <input type="text" placeholder="receiptValue" value={ this.state.receiptValue } onChange={ (e) => { this.setState({ receiptValue: e.target.value })} }/>
            </div>
            <label>Time Stamp:</label>
            <div className="">
              <input type="datetime-local" step="1" placeholder="time stamp" value={ this.state.timestamp } onChange={ (e) => { this.setState({ timestamp: e.target.value.replace('T', ' ') })} }/>
            </div>
            <button onClick={this.handleSearch}>Create Purchase</button>
          </div>
          <JSONPretty id="json-pretty" data={filteredList}></JSONPretty>
	  	  </div>
        <Modal open={ this.state.confirm } hidePopup={ this.closeConfirm }>{ Confirm }</Modal>
      </section>
    )
  }
}
